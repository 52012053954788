import React, { Component } from 'react'
import BusinessLogo from "./BusinessLogo1.png";
import './Sidebar.css';

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
            <a href="#businesslogo"> <img src={BusinessLogo} alt="" style={{width:240, marginLeft: 10}} /> </a>
            <br></br>
            <br></br>
            <div className="background-img" style={{backgroundImage: 'url(images/white-marble.png)'}} />
              <h1 id="colorlib-logo"><a href="index.html">Reliable Software Designs, LLC</a></h1>
              <span className="email"><i className="icon-mail"></i>info@ReliableSoftwareDesigns.com</span>
            </div>
            <br></br>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active"><a href="#projects" data-nav-section="projects">Projects</a></li>
                  <li><a href="#about" data-nav-section="about">About RSD - contact us</a></li>
                </ul>
              </div>
            </nav>
            <hr></hr>
            <div className="colorlib-footer">
              <p><small>
                  Last updated: December 4, 2020. <br></br>
                  Copyright © 2020,2021 Reliable Software Designs, LLC.<br></br>
                    <a href="https://ReliableSoftwareDesigns.com/TermsOfUse.html">Terms of Use</a> | <a href="https://ReliableSoftwareDesigns.com/PrivacyPolicy.html">Privacy Policy</a>
              </small></p>
            </div>
          </aside>
        </div>
      </div>
    )
  }
}
