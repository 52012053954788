import React, { Component } from 'react';
import './App.css';
import Sidebar from './components/Sidebar'
import Projects from './components/Projects'
import About from './components/About'
import {Helmet} from 'react-helmet'

class App extends Component {
  render() {
    return (
      <div>
      <Helmet>
        <title>Reliable Software Designs - Home</title>
        <meta name="description" content="Reliable Software Designs develops permissioned blockchain solutions for state licensing agencies, Amazon Neptune graph databases, and e-commerce websites." />
      </Helmet>
        <div id="colorlib-page">
          <div id="container-wrap">
            <Sidebar></Sidebar>
            <div id="colorlib-main">
              <Projects></Projects>
              <About></About>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
