import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>

        <section className="colorlib-about" data-section="about">
            <div className="colorlib-narrow-content">
            <div className="row">
                <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">About Reliable Software Designs</span>
            </div>
            </div>

            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <p>RSD is a software development company located in Aurora, Colorado. We help businesses manage complexity through custom computer software solutions, especially those that cannot maintain a full-time development staff.</p>
                </div>
                </div>
            </div>

            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <p>Our staff develops on the Microsoft Windows and Linux platforms with experience in Golang, C/C#, React.js, Node.js, Apache Tomcat, Apache Spark, Git, Scala, Visual Studio, VS Code, Mathematica, MATLAB, R, Amazon Blockchain, AWS EC2/S3/Neptune, Kubernetes, IBM Hyperledger Fabric, Docker, PostgreSQL, and SQL Server.</p>
                </div>
                </div>
            </div>

            <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Contact Us</h3>
                    <span className="email">info@ReliableSoftwareDesigns.com</span>
                </div>
                </div>
            </div>

            </div>
        </div>
        </section>
      </div>
    )
  }
}
