import React, { Component } from 'react'

export default class Projects extends Component {
  render() {
    return (
      <div>
		<section className="colorlib-work" data-section="projects">
			<div className="colorlib-narrow-content">
				<div className="row">
					<div className="col-md-4 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
					<span className="heading-meta">Projects in Development</span>
					</div>
				</div>
				<div className="row">
					
					<div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
						<div className="project" style={{backgroundImage: 'url(images/connectedGraph.jpg)', 
														//backgroundSize: 'cover',
														//resizeMode: 'cover',
														//backgroundPosition: 'center',
														//backgroundRepeat: 'no-repeat',
														//overflow: 'hidden',
														}}>
							<div className="desc">
								<div className="con">
									<h3><a href="#graphdatabase">Graph Database for Highly Connected Data</a></h3>
									<span>An Amazon Neptune graph database <a href="https://aws.amazon.com/neptune/">{'\u2728'}</a> structures 44,000 ACM TechNews articles <a href="https://aws.amazon.com/neptune/">{'\u2728'}</a> to 
										reveal the evolution of computing trends and concepts over the past 20 years. Microservices calculate over 4 million conditional probabilities using the formula of Score = Density x Linkage x Growth.
									</span>
									<p className="icon">
										<span><a href="https://AcmSearch.org">Available Feb 2, 2021<i className="icon-share3" /></a></span>
									</p>
								</div>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 animate-box" data-animate-effect="fadeInRight">
						<div className="project" style={{backgroundImage: 'url(images/blockchain-consensus.png)'}}>
							<div className="desc">
								<div className="con">
								<h3><a href="#blockchain">Permissioned Blockchain for State Licensing Agencies</a></h3>
								<span>The <strong className="text-blue">License Eligibility Authentication </strong> system 
								increases non-resident license revenue by identifying fraudulent license applications, 
								automates the shared consensus of residency rules among participating State agencies, and 
								supports the process of obtaining secure and verifiable proof of residency.
								</span>
									<p className="icon">
										<span><a href="https://ReliableSoftwareDesigns.com/">Available Jun 4, 2021<i className="icon-share3" /></a></span>
									</p>
								</div>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 animate-box" data-animate-effect="fadeInTop">
						<div className="project" style={{backgroundImage: 'url(images/FlyingMonkeys.png)'}}>
							<div className="desc">
								<div className="con">
									<h3><a href="#systemdesign">E-commerce Website Development</a></h3> 
									<span>The <strong className="text-blue">Flying Monkeys Social Club</strong> e-commerce website is an example of web design using modern development techniques. 
										<p>The cloud-hosted PostgreSQL (v12.3) database is accessed using Golang (v1.15), React.js (v16.12), React-Bootstrap (v4.4), OAuth2, Node.js (v8.16), HTML(5), CSS(3), and PayPal (v2).</p> 
									</span>
									<span>The <strong className="text-blue">Eyes of the Community</strong> website displays images of the eyes of those interviewed and their statements answering one question: What does your community need from you now?
									</span>
									<p className="icon">
										<span><a href="https://FlyingMonkeysSocialClub.herokuapp.com">Flying Monkeys Social Club<i className="icon-share3" /></a></span>
										<span><a href="http://eyesofthecommunity.net ">Eyes of the Community<i className="icon-share3" /></a></span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6 animate-box" data-animate-effect="fadeInTop">
						<div className="project" style={{backgroundImage: 'url(images/Spark.Kubernetes.jpg)'}}>
							<div className="desc">
								<div className="con">
									<h3><a href="#systemdesign">System Performance Optimization</a></h3>
									<span>The staff at <strong className="text-blue">Reliable Software Designs</strong> enjoy developing high-performance computing systems. 
										Our go-to system architecture involves parallel or concurrent algorithms executing within distributed microservices that use Scala to access an Apache Spark data-processing engine over a Kubernetes cluster, coordinated using Golang, and deployed in a Docker container.
									</span>
									<p className="icon">
										<span><a href="#projects">Available now!<i className="icon-share3" /></a></span>
									</p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</section>
      </div>
	)
  }
}
